<template>
    <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false">
        <md-content style="width: 1000px; height: 95%; overflow: auto;">
            <div class="grid">
                <div class="row no-gutters">
                    <div class="col l-12 m-12 c-12">
                        <div class="page-list-body">
                            <div class="row no-gutters page-list-header">
                                <div class="col l-6 m-6 c-12">
                                    <h4 class="page-list-title"><md-icon>list_alt</md-icon> <span>Đề nghị tạm ứng</span></h4>
                                </div>
                                <div class="col l-6 m-6 c-12">
                                    <div class="page-list-action">
                                        <md-button v-shortkey="['ctrl', 'p']" @shortkey="print()" @click="print()" class="md-raised md-view btn-add"><span>P</span>rint<md-tooltip>Print</md-tooltip></md-button>
                                        <md-button @click="close()" class="md-raised">Đóng<md-tooltip>Đóng</md-tooltip></md-button>
                                    </div>
                                </div>
                            </div>
                            <div class="form-body">
                                <div id="view-print" style="width: 850px; display: flex; flex-direction: column; justify-content: center;margin: 10px auto;">
                                    <div style="height: 100px; display: flex; justify-content: space-between;">
                                        <div style="border-bottom: 1px solid #999; 
                                            width: 25%; display: flex;align-items: center; justify-content: center;
                                            border-top: 1px solid #999;border-left: 1px solid #999;">
                                            <img style="height: 50px; width: auto;" src="../../../assets/images/logo.png" />
                                        </div>
                                        <div style="border-bottom: 1px solid #999;
                                            border-left: 1px solid #999;
                                            border-top: 1px solid #999;
                                            border-right: 1px solid #999;
                                            width: 45%;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            text-transform: uppercase;
                                            font-weight: bold;">
                                            <div class="title"><h2>ĐỀ NGHỊ TẠM ỨNG</h2></div>
                                        </div>
                                        <div style="border-top: 1px solid #999; border-right: 1px solid #999;border-bottom: 1px solid #999; width: 30%; display: flex; flex-direction: column; justify-content: space-around; padding: 10px;">
                                            <div><span style="font-weight: bold;">Số: </span><span>{{entity.advancePaymentCode}}</span></div>
                                            <div><span style="font-weight: bold;">Ngày: </span><span>{{entity.advancePaymentDate}}</span></div>
                                        </div>
                                    </div>
                                    <div style="display: flex; flex-direction: column; padding: 10px;">
                                        <div style="display: flex; padding: 5px 0;">
                                            <div style="font-weight: bold;font-size: 16px; padding-right: 10px;">Nhân viên:</div>
                                            <div style="font-size: 16px;">{{ entity.staff.fullName  }}</div>
                                        </div>
                                        <div style="display: flex; padding: 5px 0;">
                                            <div style="font-weight: bold;font-size: 16px; padding-right: 10px;">Nội dung:</div>
                                            <div style="font-size: 16px;">{{ entity.note  }}</div>
                                        </div>
                                    </div>
                                    <div style="display: flex; margin: 10px auto;">
                                        <div style="display: flex; margin: 10px auto;">
                                            <table style="border-collapse: collapse;border-spacing: 0; min-height: auto !important;">
                                                <thead>
                                                    <tr>
                                                        <th style="width:50px; padding: 5px 10px; border: 1px solid #e0e0e0;">Stt</th>
                                                        <th style="width:300px; padding: 5px 10px; border: 1px solid #e0e0e0;">Tên phí</th>
                                                        <th style="width:200px; padding: 5px 10px; border: 1px solid #e0e0e0;">Loại phí</th>
                                                        <th style="width:150px; padding: 5px 10px; border: 1px solid #e0e0e0;">Dự toán</th>
                                                        <th style="width:150px; padding: 5px 10px; border: 1px solid #e0e0e0;">Tạm ứng</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(item, index) in entity.details" :key="'item-'+ index">
                                                        <td style="border: 1px solid #e0e0e0; padding: 5px 0px; text-align: center;">{{index + 1}}</td>
                                                        <td style="border: 1px solid #e0e0e0; padding: 5px; text-align: left;">{{ item.fee.feeName }}</td>
                                                        <td style="border: 1px solid #e0e0e0; padding: 5px 10px 5px 10px;">{{ getFeeType(item.type) }}</td>
                                                        <td style="border: 1px solid #e0e0e0; padding: 5px; text-align: right;">
                                                            {{ formatNumber(item.estimateAmount) }}
                                                        </td>
                                                        <td style="border: 1px solid #e0e0e0; padding: 5px; text-align: right;">
                                                            {{ formatNumber(item.advancePaymentAmount) }}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="4" style="text-align: right; border: 1px solid #e0e0e0; padding: 5px 10px 5px 20px;"><span style="font-weight: bold;font-size: 16px;">Tổng tạm ứng</span></td>
                                                        <td style="border: 1px solid #e0e0e0; padding: 5px;text-align: right; "><span style="font-weight: bold; font-size: 16px;">{{ formatNumber(entity.amount) }}</span></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div style="display: flex;justify-content: space-between;padding: 20px 30px 50px 30px;">
                                        <div v-for="(item, index) in entity.approveList" :key="'approve-'+ index" style="display: flex; flex-direction: column; width: 40%; font-size: 17px; text-transform: none;">
                                            <div style="font-weight: bold; text-align: center;">
                                                {{ item.title }}
                                            </div>
                                            <div style="font-weight: bold; text-align: center; padding-top: 50px;">
                                                {{ item.staff.fullName }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </md-content>
    </md-dialog>
</template>

<script>
    import projectAdvancePaymentService from '@/api/projectAdvancePaymentService';
    import messageBox from '@/utils/messageBox';
    import { mapActions } from 'vuex';

    export default ({
        data() {
            return {
                showDialog: false,
                id: 0,
                estimateList: [],
                entity: { staff: { fullName: '' } }
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            print () {
                this.$htmlToPaper('view-print');
            },

            open(id){
                this.id = id;
                this.getById();
                this.showDialog = true;
            },
            close(){
                this.showDialog = false;
            },

            getById(){
                this.setLoading(true);
                projectAdvancePaymentService.getById(this.id).then((response) => {
                    if(response.statusCode == 200){
                        for(let i=0; i< response.data.estimateList.length; i++){
                            this.estimateList.push({id: response.data.estimateList[i].estimateId, estimateCode: response.data.estimateList[i].estimate.estimateCode});
                        }
                        this.entity = response.data;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },
        },
    })
</script>